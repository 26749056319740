
import _ from 'lodash';
import { Inject } from 'inversify-props';
import { Component, Prop, Vue } from 'vue-property-decorator';

import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';

import ReportItemMenu from './report-item-menu.vue';
import ScheduledItemModel from '../models/scheduled-item.model';
import ReportsService, { ReportsServiceS } from '../reports.service';
import { DATA_TYPE } from '../constants';
import ScheduledReportsTooltip from './preview-tooltip.vue';

@Component({
    components: {
        ModalWrapper,
        ReportItemMenu,
        ScheduledReportsTooltip,
    },
    filters: {
        dateFilter: (date: string| null) => {
            if (!date) {
                return '-';
            }
            const d = new Date(date);
            const day = (`0${d.getUTCDate()}`).slice(-2);
            const month = (`0${d.getUTCMonth() + 1}`).slice(-2);
            return `${day}/${month}/${d.getUTCFullYear()}`;
        },
    },
})
export default class ReportsList extends Vue {
    @Inject(ReportsServiceS) private reportsService!: ReportsService;

    @Prop({ type: String, required: true })
    dataType!: DATA_TYPE;

    @Prop({ type: Boolean, default: false })
    isLoading!: boolean;

    private isAllReportsShown = false;
    private wasAllReportsShown = false;

    reportMarkElement: HTMLElement | null = null;
    currentReport: ScheduledItemModel | null = null;

    setTooltipData(event: MouseEvent, report: ScheduledItemModel) {
        this.reportMarkElement = event.target as HTMLElement;
        this.currentReport = report;
    }

    handleNewReport() {
        this.$emit('newReport');
    }

    handleDeleteReport(reportId: string) {
        this.$emit('deleteReport', reportId);
    }

    handleEditReport(reportId: string) {
        if (!this.reports) {
            return;
        }

        const report = this.reports.find(r => r.id === reportId);

        if (!report) {
            return;
        }

        this.$emit('editReport', report);
    }

    handleToggleShownReports() {
        this.isAllReportsShown = !this.isAllReportsShown;
    }

    reportFilters(report: ScheduledItemModel) {
        const filters = JSON.parse(JSON.stringify(report.filters)) as { [key: string]: string | number };
        return Object.entries(filters).reduce((acc, [key, value]) => `${acc}\n${key}=${value}`, '');
    }

    get customColumns() {
        return (this.$parent as any).customColumns;
    }

    get reports() {
        const isCluster = (() => this.dataType === DATA_TYPE.RATES_CLUSTER
            || this.dataType === DATA_TYPE.MARKETS_CLUSTER
            || this.dataType === DATA_TYPE.RANKING_CLUSTER
        )();

        let dt = this.dataType;
        if (this.isAllReportsShown) {
            dt = isCluster ? DATA_TYPE.CLUSTER_ALL : DATA_TYPE.HOTEL_ALL;
        }

        if (this.isAllReportsShown !== this.wasAllReportsShown) {
            this.reportsService.resetReports();
            this.wasAllReportsShown = this.isAllReportsShown;
        }

        return this.reportsService.getReports(dt);
    }

    hasFields(report: ScheduledItemModel) {
        return !!report.filters && Object.keys(report.filters).length > 0 || !!report.compsetId;
    }

    mapDataType(dataType: DATA_TYPE) {
        switch (dataType) {
            case DATA_TYPE.RATES_CLUSTER:
            case DATA_TYPE.RATES: return 'rate';
            case DATA_TYPE.MARKETS_CLUSTER:
            case DATA_TYPE.MARKETS: return 'market';
            case DATA_TYPE.RANKING_CLUSTER:
            case DATA_TYPE.RANKING: return 'guestReviews';
            case DATA_TYPE.DI_LITE: return 'DILight';
            case DATA_TYPE.RATES_COMPARE: return 'ratesCompareMode';
            default: throw new Error('Wrong data type!');
        }
    }
}
